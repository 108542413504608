<template>
  <v-container>
    <h3>Create Manufacturer Format</h3>
    <v-card class="mt-2" max-width="600">
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Create Form</div>
          <v-list-item-title class="text-h5 mb-1">
            <v-text-field v-model="selectedMftName" label="Enter New Manufacturer Format Name"></v-text-field>
            <v-select
              v-model="selectedMftName"
              :loading="fetchingFileList"
              :items="manufacturerNameFileList"
              label="Select Existing Sample Manufacturer File"
            ></v-select>

            <v-file-input
              v-model="selectedFile"
              counter
              show-size
              truncate-length="15"
              placeholder="Upload your documents"
              label="To add a new file, upload a CSV or Json file here"
              accept=".json, .csv"
            >
            </v-file-input>
          </v-list-item-title>
          <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          color="primary"
          rounded
          outlined
          text
          v-on:click="addManufacturerClick"
          :loading="isAdding"
          :disabled="fetchingFileList"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--     
    // TODO: Update display of apiResult to filter it
    // "bad requests" should output Invalid Request
    // Missing Manufacturer Name input or no selected Manufacturer file should warn before calling API - like "Manufacturer name is required" and "Manufacturer File is a required selection"
    -->

    <v-card class="mt-2" max-width="800">
      <p>
        {{ this.apiResult }}
      </p>
    </v-card>
  </v-container>
</template>
<script>
import dal from '../services/dal.js';
export default {
  name: 'AddNew',
  data: () => ({
    isAdding: false,
    apiResult: null,
    manufacturerNameFileList: [],
    selectedMftName: null,
    selectedFile: null,
    fetchingFileList: false,
  }),
  computed: {
    accessToken() {
      return this.$store.state.token;
    },
  },
  mounted() {
    this.fetchingFileList = true;
    dal
      .getManufacturerNamesFromFiles(this.accessToken)
      .then((data) => {
        this.manufacturerNameFileList = data.data;
      })
      .finally(() => {
        this.fetchingFileList = false;
      });
  },
  methods: {
    addManufacturerClick() {
      this.isAdding = true;
      this.apiResult = this.selectedMftName;
      dal
        .addManufacturer(this.accessToken, this.selectedMftName)
        .then((data) => {
          this.apiResult = data;
          if (this.selectedFile) {
            this.uploadFile();
          }
        })
        .finally(() => {
          this.isAdding = false;
        });
    },
    uploadFile() {
      const formData = new FormData();
      let currentDateTime = new Date().toJSON();
      console.log(currentDateTime);
      currentDateTime = currentDateTime.replace('.00Z', '').replace('.', '').replace(':', '-').replace('T', '-').replace('Z', '');
      console.log(currentDateTime);
      const ext = this.selectedFile.name.split('.').pop();
      const newFileName = `${this.selectedMftName}_${currentDateTime}.${ext}`;
      const newFile = new File([this.selectedFile], newFileName, { type: this.selectedFile.type });
      formData.append(newFileName, newFile);
      dal
        .uploadFile(this.accessToken, formData)
        .then((data) => {
          console.log(data);
          this.manufacturerNameFileList.push(newFile.name);
          this.selectedFile = null;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
