import { getInstance } from './index';
import jwt_decode from 'jwt-decode';
import store from '../store/index'
import { GLOBAL_ADMIN_PERMISSION } from '../constants';


export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {

    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      let permissions = []
      await authService.getTokenSilently().then(token => {
        store.commit('setToken', token);
        permissions = jwt_decode(token).permissions;
        store.commit('setUserPermissions', permissions);
      });

      if (!permissions.includes(GLOBAL_ADMIN_PERMISSION)) {
        return next('/protected');
      } else {
        return next();
      }
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
