const truncate = function(value, length, omission = '...') {
    if (!value || typeof value !== 'string') return '';
  
    if (value.length <= length) {
      return value;
    } else {
      return value.substring(0, length) + omission;
    }
  };
  
  export default truncate;