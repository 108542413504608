var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pl-3 pt-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Dead Letter Summary")])])],1),_c('v-expansion-panels',_vm._l((_vm.deadLetterSummary),function(item,i){return _c('v-expansion-panel',{key:i,on:{"click":function($event){return _vm.loadDeadLetterRollup($event, item.queueName)}}},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(item.queueName[0].toUpperCase() + item.queueName.slice(1))+"  ("+_vm._s(item.count)+") ")]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.deadLetterRollup,"loading":_vm.loading,"loading-text":"Analyzing Dead Letter Messages, this may take a while"},scopedSlots:_vm._u([{key:"item.sourceFileName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                name: 'DeadLetterMessages',
                params: {
                  queueName: item.queueName,
                  sourceFileName: item.sourceFileName,
                  fromSequenceNumber: item.fromSequenceNumber,
                  queueCount: item.count,
                },
              }}},[_vm._v(" "+_vm._s(item.sourceFileName)+" ")])]}}],null,true)})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }