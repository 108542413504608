<template>
  <div>
    <v-app-bar color="#007acc" dark short class="hidden-sm-and-down">
      <v-toolbar-title>
        <div class="d-flex align-center">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="../assets/SKUworld-logo-v2.png"
            transition="scale-transition"
            width="140"
          />
        </div>
        <span></span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <span class="pt-3" v-if="userPermissions && userPermissions.includes(GLOBAL_ADMIN_PERMISSION)">
          <v-btn v-for="item in menu" v-if="loggedIn == item.loginShow" :key="item.title" :to="item.link" text>
            {{ item.title }}
          </v-btn>
        </span>
        <span class="pt-3">
          <v-btn v-if="loggedIn" v-on:click="logOut" text>
            <span>Logout</span>
          </v-btn>
        </span>
      </v-toolbar-items>
    </v-app-bar>

    <v-app-bar color="#007acc" short class="hidden-md-and-up">
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon color="white" v-bind="attrs" v-on="on"> </v-app-bar-nav-icon>
        </template>
        <v-list>
          <div v-if="userPermissions && userPermissions.includes(GLOBAL_ADMIN_PERMISSION)">
            <v-list-item v-for="(item, index) in menu" v-if="loggedIn == item.loginShow" :key="index" :to="item.link">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </div>
          <v-list-item :href="prodApp.link" target="_blank"
            ><v-list-item-title>Return to SKUWorld App</v-list-item-title></v-list-item
          >
          <v-list-item v-if="loggedIn" v-on:click="logOut">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<script>
import { GLOBAL_ADMIN_PERMISSION } from '../constants';

export default {
  name: 'NavBar',

  components: {},
  data: () => {
    return {
      GLOBAL_ADMIN_PERMISSION: GLOBAL_ADMIN_PERMISSION,
      menu: [
        {
          icon: 'warning',
          title: 'Manufacturer Formats',
          link: '/manage-manufacturers',
          text: 'Manufacturer Formats',
          loginShow: true,
        },
        {
          icon: 'info',
          title: 'View Intake',
          link: '/view-intake',
          text: 'View Intake',
          loginShow: true,
        },
        {
          icon: 'info',
          title: 'Dead Letter Summary',
          link: '/dead-letter-summary',
          text: 'Dead Letter Summary',
          loginShow: true,
        },
        {
          icon: 'info',
          title: 'Help',
          link: '/help',
          text: 'Help',
          loginShow: true,
        },
        {
          icon: 'warning',
          title: 'Login',
          link: '/login',
          text: 'Login',
          loginShow: false,
        },
      ],
      prodApp: {
        icon: 'warning',
        title: 'Return to SKUWorld App',
        link: '/',
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$auth.isAuthenticated;
    },

    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  methods: {
    menuItems: () => {
      return this.menu;
    },

    postmanLink: () => {
      return this.postman;
    },

    logOut() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },

    async login() {
      this.$auth.loginWithRedirect();
      this.$store.dispatch('setIsAuthenticated', true);
    },
  },
  created() {},
};
</script>
