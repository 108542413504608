<template>
  <div>
    <v-row class="pl-3 pt-3">
      <v-col cols="12">
        <h3>Intake Process Runs</h3>
      </v-col>
    </v-row>

    <v-data-table
      :headers="intakeProcessingHeaders"
      :items="intakeProcessingList"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-row class="pl-4 pr-4">
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-text-field
              v-model="searchFileName"
              append-icon="mdi-magnify"
              label="Search File Name"
              single-line
              hide-details
              clearable
              @input="onSearchChange"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-select
              v-model="selectedMftId"
              :items="manufacturers"
              label="Select Manufacturer"
              item-value="value"
              item-text="text"
              v-on:change="getIntakeProgressData"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pl-4 pr-4">
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-select
              v-model="selectedIntakeStatus"
              :items="intakeStatus"
              label="Select Status"
              item-value="value"
              item-text="text"
              v-on:change="getIntakeProgressData"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-select
              v-model="selectedSortBy"
              :items="sortBy"
              label="Sort by"
              item-value="value"
              item-text="text"
              v-on:change="getIntakeProgressData"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.fileName`]="{ item }">
        <router-link
          :to="{
            name: 'IntakeProgressDetail',
            params: { intakeId: item.intakeProgressId },
          }"
        >
          {{ item.fileName }}
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dal from '../services/dal';

export default {
  name: 'IntakeProgress',
  data: () => ({
    intakeProcessingHeaders: [
      { text: 'File Name', value: 'fileName', sortable: false },
      { text: 'Manufacturer Format Name', value: 'manufacturerName', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Start Date', value: 'startDate', sortable: false },
      { text: 'End Date', value: 'endDate', sortable: false },
      { text: 'Records Received In File', value: 'recordsReceivedInFile', sortable: false },
      { text: 'Products Updated', value: 'productsUpdated', sortable: false },
      { text: 'Products Added', value: 'productsAdded', sortable: false },
    ],
    intakeProcessingList: [],
    searchFileName: '',
    selectedMftId: '',
    selectedSortBy: 'StartDate-desc',
    selectedIntakeStatus: 'all',
    manufacturers: [],
    sortBy: [
    {
        text: 'Start Date (Newest to Oldest)',
        value: 'StartDate-desc',
      },
      {
        text: 'Start Date (Oldest to Newest)',
        value: 'StartDate-asc',
      },
      {
        text: 'End Date (Newest to Oldest)',
        value: 'EndDate-desc',
      },
      {
        text: 'End Date (Oldest to Newest)',
        value: 'EndDate-asc',
      },
    ],
    intakeStatus: [
      {
        text: 'All',
        value: 'all',
      },
      {
        text: 'Queued',
        value: 'queued',
      },
      {
        text: 'In Progress',
        value: 'in progress',
      },
      {
        text: 'Succeeded',
        value: 'succeeded',
      },
      {
        text: 'Failed',
        value: 'failed',
      },
    ],
  }),
  components: {},
  computed: {
    manufacturerId() {
      return this.$store.state.manufacturerId;
    },

    accessToken() {
      return this.$store.state.token;
    },
  },

  mounted() {
    this.getIntakeProgressData();
    dal.getManufacturers(this.accessToken).then((data) => {
      if (data && data.data && data.data.result) {
        this.manufacturers = data.data.result.map((item) => {
          return {
            text: item.manufacturerName,
            value: item.manufacturerId,
          };
        });
      }
    });
  },

  methods: {
    getIntakeProgressData() {
      dal.getIntakeProgressData(this.accessToken, this.getFilters()).then((data) => {
        this.intakeProcessingList = data.data;
      });
    },

    onSearchChange() {
      setTimeout(() => {
        this.getIntakeProgressData();
      }, 400);
    },

    getFilters() {
      let filters = {};

      if (this.searchFileName && this.searchFileName != '') {
        filters.fileName = this.searchFileName;
      }

      if (this.selectedMftId) {
        filters.manufacturerId = this.selectedMftId;
      }

      if (this.selectedSortBy) {
        const sortBy = this.selectedSortBy.split('-');
        filters.sortBy = sortBy[0];
        filters.sortOrder = sortBy[1];
      }

      if (this.selectedIntakeStatus && this.selectedIntakeStatus != 'all') {
        filters.intakeStatus = this.selectedIntakeStatus;
      }

      return JSON.stringify(filters);
    },
  },
};
</script>
