<template>
  <div class="mt-12 text-center font-weight-bold">You do not have access to this page.</div>
</template>

<script>
export default {
  name: 'Protected',
  components: {},
  mounted() {},
};
</script>
