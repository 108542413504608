import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import AddManufacturer from '../views/AddManufacturer.vue';
import ManageManufacturerList from '../views/ManageManufacturerList.vue';
import ManageManufacturerMappings from '../views/ManageManufacturerMappings.vue';
import IntakeProgress from '../views/IntakeProgress.vue';
import IntakeProgressDetail from '../views/IntakeProgressDetail.vue';
import DeadLetterSummary from '../views/DeadLetterSummary.vue';
import DeadLetterMessages from '../views/DeadLetterMessages.vue';
import Protected from '../views/Protected.vue';
import { authGuard } from '../auth/authGuard';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: authGuard,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/add-manufacturer',
    name: 'AddManufacturer',
    component: AddManufacturer,
    beforeEnter: authGuard,
  },
  {
    path: '/manage-manufacturers',
    name: 'ManageManufacturerList',
    component: ManageManufacturerList,
    beforeEnter: authGuard,
  },
  {
    path: '/manage-manufacturer/mappings/:manufacturerId',
    name: 'ManageManufacturerMappings',
    component: ManageManufacturerMappings,
    beforeEnter: authGuard,
  },
  {
    path: '/view-intake',
    name: 'IntakeProgress',
    component: IntakeProgress,
    beforeEnter: authGuard,
  },
  {
    path: '/dead-letter-summary',
    name: 'DeadLetterSummary',
    component: DeadLetterSummary,
    beforeEnter: authGuard,
  },
  {
    path: '/dead-letter-messages',
    name: 'DeadLetterMessages',
    component: DeadLetterMessages,
    beforeEnter: authGuard,
  },
  {
    path: '/intake-detail/:intakeId',
    name: 'IntakeProgressDetail',
    component: IntakeProgressDetail,
    beforeEnter: authGuard,
  },
  {
    path: '/protected',
    name: 'Protected',
    component: Protected,
  },
  {
    path: '/about',
    name: 'About',
    beforeEnter: authGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue');
    },
  },
  {
    path: '/help',
    name: 'Help',
    beforeEnter: authGuard,
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Help.vue');
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
