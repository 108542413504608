<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h3 class="display-1 font-weight-bold mb-3">PDI Manager</h3>
        <v-btn v-on:click="syncNow" v-if="!isSyncing" block>
          <span>Get Manufacturer</span>
        </v-btn>
        <v-card v-if="isSyncing">
          Trying to get Data... this will take a few minutes
          <br />
          <br />
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <br />
        </v-card>

        <p class="subheading font-weight-regular">
          <br />
        </p>
        <h1 class="display-1 font-weight-bold mb-3">
          {{ selectedManufacturer }}
        </h1>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="apiMessage.result"
          :single-select="singleSelect"
          :items-per-page="20"
          @click:row="handleClick"
          show-select
          item-key="manufacturerId"
          :class="{ selectedRow: apiMessage.result === selectedItem }"
        ></v-data-table>

        <v-data-table
          v-if="manufacturerMappings.data"
          :headers="mappingHeaders"
          :items="manufacturerMappings.data"
          :items-per-page="25"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>My CRUD</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> New Item </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.manufacturerFieldName"
                            label="Manufacturer Field Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.mappingTypeSymbol"
                            label="Mapping Type Symbol"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.destinationFieldName"
                            label="Destination Field Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="10" md="10">
                          <v-select
                            :items="manufactureFieldsListControl"
                            label="Source Manufacturer Field"
                          ></v-select>
                        </v-col>
                        <!--
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.protein"
                              label="Protein (g)"
                            ></v-text-field>
                          </v-col>
                        -->
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
                    <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this item?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:no-data>
            <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
            No data found
          </template>
        </v-data-table>
      </v-col>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3" v-if="false">Documents</h2>

        <v-row justify="center" v-if="false">
          <a
            v-for="(next, i) in whatsNext"
            :key="i"
            :href="next.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ next.text }}
          </a>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//     :class="[item.selected && 'selected']"
import dal from '../services/dal.js';
export default {
  name: 'HomePage',

  data: () => ({
    formTitle: null,
    dialog: false,
    dialogDelete: false,
    selectedManufacturerId: 0,
    selectedManufacturer: '-',
    headers: [
      {
        text: 'Manufacturer Format Name',
        align: 'start',
        sortable: false,
        value: 'manufacturerName',
      },
      { text: 'manufacturerId', value: 'manufacturerId' },
      { text: 'updateDate', value: 'updateDate' },
      { text: 'createDate', value: 'createDate' },
      { text: 'isActive', value: 'isActive' },
    ],
    singleSelect: true,
    selected: [],
    selectedItem: null,
    isSyncing: false,
    apiMessage: {},
    editedIndex: -1,
    editedItem: {},
    manufactureFieldsList: null,
    manufactureFieldsListControl: ['yrdy', 'yrdy'],
    manufacturerMappings: {},
    mappingHeaders: [
      {
        text: 'manufacturerFieldName',
        align: 'start',
        sortable: false,
        value: 'manufacturerFieldName',
      },
      { text: 'mappingTypeSymbol', value: 'mappingTypeSymbol' },
      { text: 'destinationFieldName', value: 'destinationFieldName' },
      { text: 'updateDate', value: 'updateDate' },
      { text: 'createDate', value: 'createDate' },
      { text: 'isActive', value: 'isActive' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),
  computed: {
    accessToken() {
      return this.$store.state.token;
    },
  },

  mounted() {},
  methods: {
    syncNow() {
      this.isSyncing = true;
      dal.getManufacturers(this.accessToken).then((data) => {
        this.isSyncing = false;
        this.apiMessage = data.data;
        this.apiMessage.result.map((item, index) => {
          item.selectedItem = false;

          this.$set(this.apiMessage.result, index, item);
        });
      });
    },
    handleClick(row) {
      // set active row and deselect others
      this.selectedManufacturer = row.manufacturerName;
      this.selectedItem = row;
      this.$store.commit('setManufacturerId', row.manufacturerId);
      // this.highlightClickedRow(row)
      this.apiMessage.result.map((item, index) => {
        item.selected = item === row;

        this.$set(this.apiMessage.result, index, item);
      });
      dal.getManufacturesMappings(row.manufacturerId).then((data) => {
        this.manufacturerMappings = data;
      });
      dal
        .getManufacturersFieldsByManufacturerId(row.manufacturerId)
        .then((data) => {
          this.manufactureFieldsList = [];
          var i = 0;
          var justAnArray = [];
          data.data.forEach(function(thisItem) {
            justAnArray[i] = thisItem.manufacturerFieldName;
            i = i + 1;
          });
          this.manufactureFieldsListControl = justAnArray;
        });

      // or just do something with your current clicked row item data
    },
    highlightClickedRow(event) {
      let tr = event.target.parentNode;
      tr.classList.add('highlight');
    },
    editItem(item) {
      this.editedIndex = item.manufacturerMappedFieldId;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.manufacturerMappings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        //this.editedIndex = -1
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        //this.editedIndex = -1
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
<style scoped>
.selected {
  background-color: red;
}
.selectedRow {
  background-color: red;
  font-weight: bold;
}
</style>
