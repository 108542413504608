<template></template>

<script>
export default {
  name: 'Home',
  components: {},
  mounted() {
    this.$router.push('/manage-manufacturers');
  },
};
</script>
