import { MappingType } from '../constants';
import dal from '../services/dal.js';

export const mappingsCommonData = {
  data() {
    return {
      formTitle: 'Edit Mapping',
      editDialog: false,
      editedIndex: -1,
      editedItem: {
        name: '',
      },
      selectedMappingType: MappingType.FIELD,

      setMappingFieldTo: null,
      setHardCodeTo: null,
      setFormulaTo: null,
      setTransformTo: null,
      setTransformArgumentsTo: null,

      sourceFieldDelimiter: '|',
      sourceFieldIsDelimited: false,
      sourceFieldDelimiterRules: [
        (v) => !!v || 'Delimiter is required',
        (v) => v === '|' || v.length == 2 || 'Delimiter must be a single character',
      ],
      ordinalPosition: 0,
      ordinalPositionRules: [(v) => (!isNaN(v) && v >= 0) || 'Ordinal position must be a number'],
      showMappingFields: true,
      showHardCodeFields: false,
      showFormulaFields: false,
      showTransformationFields: false,
      truncColumns: true,
      truncColumnsButtonText: 'Show More',
      globalManufacturerId: null,
      manufacturerMappings: null,
      isFetching: false,
      confirmUnMapDialog: false,
      isUnMapping: false,
      selectedManufacturerMappedFieldId: null,
      defaultItem: {},
    };
  },
  mounted() {
    this.globalManufacturerId = this.$route.params.manufacturerId;
    this.$store.commit('setManufacturerId', this.$route.params.manufacturerId);
  },
  computed: {
    computedTableData() {
      return this.manufacturerMappings && this.manufacturerMappings.map((item) => item);
    },
    formulaForNonTransformTypeMapping() {
      return this.sourceFieldIsDelimited && this.editedItem && this.editedItem.manufacturerFieldName
        ? `[${this.editedItem.manufacturerFieldName}, ${this.sourceFieldDelimiter}, ${this.ordinalPosition || 0}]`
        : null;
    },
    manufacturerName() {
      return this.$store.state.manufacturerName;
    },

    manufacturerId() {
      return this.$store.state.manufacturerId;
    },

    accessToken() {
      return this.$store.state.token;
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },

    updateSpelling: function (value) {
      if (value == 'lenght') return 'length';
      return value;
    },

    showTransformationFieldName: function (value) {
      if (value.includes('#')) {
        return value.replace(/([A-Z])/g, ' $1').replace('#', 'Transformation - ');
      }
      return value;
    },
  },
  methods: {
    populateSourceDelimitedFields() {
      try {
        let parsedFormula = this.setFormulaTo.replace('[', '').replace(']', '');
        parsedFormula = parsedFormula.split(',').map((item) => item.trim());
        const sourceFieldDelimiter = parsedFormula[1] || null;
        const ordinalPosition = parsedFormula[2] || null;
        if (sourceFieldDelimiter) {
          this.sourceFieldDelimiter = sourceFieldDelimiter;
          this.ordinalPosition = ordinalPosition || 0;
          this.sourceFieldIsDelimited = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    setManufacturerMappingType(mapType) {
      this.showMappingFields = mapType === MappingType.FIELD;
      this.showHardCodeFields = mapType === MappingType.HARDCODE;
      this.showFormulaFields = mapType === MappingType.FORMULA;
      this.showTransformationFields = mapType === MappingType.TRANSFORM;
    },

    onSelectTransformation(transformation) {
      this.setTransformTo = transformation;
    },

    closeEditDialog() {
      this.editedIndex = -1;
      this.editDialog = false;
      this.setTransformArgumentsTo = null;
      this.setFormulaTo = null;
      this.setMappingFieldTo = null;
      this.sourceField = null;
      this.setTransformTo = null;
      this.showMappingFields = false;
      this.showTransformationFields = false;
      this.selectedManufacturerMappedFieldId = null;
      this.editedItem = { ...this.defaultItem };
    },

    unMapHandler() {
      this.unMap()
        .then(() => {
          this.initData(this.manufacturerId);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.closeEditDialog();
          this.confirmUnMapDialog = false;
          this.isUnMapping = false;
        });
    },

    unMap() {
      this.isUnMapping = true;
      return dal.unMap(this.accessToken, this.manufacturerId, this.editedItem.manufacturerMappedFieldId);
    },

    updateMapping(reqBody) {
      this.isMapping = true;
      return dal.updateMapping(this.accessToken, reqBody);
    },

    sortFn(a, b) {
      const nameA = a.destinationFieldName.toUpperCase(); // ignore upper and lowercase
      const nameB = b.destinationFieldName.toUpperCase(); // ignore upper and lowercase
      //sort the # fields to the bottom

      if (nameA.charAt(0) === '#' && nameB.charAt(0) !== '#') {
        return 1;
      }
      if (nameA.charAt(0) !== '#' && nameB.charAt(0) === '#') {
        return -1;
      }

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
};
