<template>
  <div>
    <v-row class="pl-3 pt-3">
      <v-col cols="12">
        <h3 v-if="this.manufacturerName">Manage {{ this.manufacturerName }} Mappings</h3>

        <div class="justify-end ma-1 row">
          <v-btn :loading="loadingPipelineDialog" @click="startPipelineItem()">
            <v-icon small class="mr-2">mdi-launch </v-icon>Start Pipeline</v-btn
          >

          <v-btn :loading="loadingSampleDataDialog" class="ml-2 mb-1" @click="openSampleDataDialog()"
            >Sample Source Data</v-btn
          >
          <v-btn class="ml-2" @click="publishDialog = true">
            Publish Mapping
            <v-icon small class="mr-2"> mdi-publish </v-icon>
          </v-btn>
        </div>

        <v-dialog v-model="pipelineDialog" width="500">
          <v-card>
            <v-card-title class="text-h5">Please select a source file</v-card-title>
            <v-container>
              <v-row class="center">
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-if="manufacturerFileList"
                    v-model="sourceFileName"
                    :items="manufacturerFileList"
                    label="Select Source File"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="startPipelineItemCancel">Cancel</v-btn>
              <v-btn v-if="sourceFileName" @click="startPipelineItemOk">Start Pipeline</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog class="overflow-x-hidden" v-model="sampleDataDialog" max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Sample Data</v-card-title>
            <v-card-subtitle class="align-baseline justify-lg-space-between row pt-10 pb-10">
              <div style="display: flex; align-items: baseline">
                <v-spacer></v-spacer>
                <download-csv v-if="manufacturerSampleData" class="ml-3" :data="manufacturerSampleData">
                  <v-btn>
                    <v-icon>mdi-launch </v-icon>
                    Download Sample CSV
                  </v-btn>
                </download-csv>
                <v-spacer></v-spacer>
                <!-- <v-btn class="ma-3" @click="ToggleSampleDataColumns">{{ truncColumnsButtonText }}</v-btn> -->
              </div>

              <v-btn color="blue darken-1" text @click="closeDialogData">Close</v-btn>
            </v-card-subtitle>
            <!-- <v-container>
              <v-data-table
                :headers="manufacturerSampleDataHeaders"
                :items="manufacturerSampleData"
                class="ma-3 elevation-5 pa-3"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td
                      v-for="(header, key) in manufacturerSampleDataHeaders"
                      :key="key"
                      v-bind:class="{ truncate: truncColumns }"
                      class="with-divider"
                    >
                      {{ item[header.value] }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-container> -->
          </v-card>
        </v-dialog>

        <v-dialog v-model="publishDialog" width="700">
          <v-card>
            <v-card-title class="text-h5">Confirm you want to publish this mapping</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="publishDialog = false">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="publishItemConfirm">Publish Mapping</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Tab view -->
        <v-tabs fixed-tabs>
          <v-tab> Source to Destination </v-tab>
          <v-tab> Destination to Source </v-tab>
          <v-tab-item>
            <MftMappingsSourceToDestination />
          </v-tab-item>
          <v-tab-item>
            <MftMappingsDestinationToSource />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MftMappingsDestinationToSource from '../components/MftMappingsDestinationToSource';
import MftMappingsSourceToDestination from '../components/MftMappingsSourceToDestination';
import dal from '../services/dal.js';

export default {
  name: 'ManageManufacturerMappings',
  data: () => ({
    publishDialog: false,
    manufacturerSampleData: null,
    manufacturerSampleDataHeaders: [
      { text: 'Product Name', value: 'ProductName', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Product Url', value: 'ProductURL', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Image Url', value: 'ImageURL', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'SKU', value: 'SKU', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Short Description', value: 'ShortDescription', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Warranty Url', value: 'WarrantyURL', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Color', value: 'Color', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Collection', value: 'Collection', class: 'with-divider', cellClass: 'with-divider' },
      {
        text: 'Edge Profile',
        value: 'Edge Profile',
        class: 'with-divider',
        cellClass: 'with-divider',
      },
      { text: 'Flooring Type', value: 'Flooring Type', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Installation Grade', value: 'Installation Grade', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Made in the USA', value: 'Made in the USA', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Plank Length', value: 'Plank Length', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Plank Thickness', value: 'Plank Thickness', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Plank Width', value: 'Plank Width', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Style', value: 'Style', class: 'with-divider', cellClass: 'with-divider' },
      {
        text: 'Tongue  Groove Profile',
        value: 'Tongue  Groove Profile',
        class: 'with-divider',
        cellClass: 'with-divider',
      },
      { text: 'Backing', value: 'Backing', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Break Strength', value: 'Break Strength', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Color Variation', value: 'Color Variation', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Construction', value: 'Construction', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Country of Origin', value: 'Country of Origin', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Face Weight', value: 'Face Weight', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Fiber', value: 'Fiber', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Fiber Brand', value: 'Fiber Brand', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Finish', value: 'Finish', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Frost Resistant', value: 'Frost Resistant', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Glaze Hardness', value: 'Glaze Hardness', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Gloss Level', value: 'Gloss Level', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Installation Method', value: 'Installation Method', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Length', value: 'Length', class: 'with-divider', cellClass: 'with-divider' },
      {
        text: 'Nominal Plank Thickness',
        value: 'Nominal Plank Thickness',
        class: 'with-divider',
        cellClass: 'with-divider',
      },
      { text: 'Pattern Repeat', value: 'Pattern Repeat', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Product Type', value: 'Product Type', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Radiant Heat', value: 'Radiant Heat', class: 'with-divider', cellClass: 'with-divider' },
      {
        text: 'Shade  Texture Variation',
        value: 'Shade  Texture Variation',
        class: 'with-divider',
        cellClass: 'with-divider',
      },
      { text: 'Slip Resistant', value: 'Slip Resistant', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Species', value: 'Species', class: 'with-divider', cellClass: 'with-divider' },
      {
        text: 'Sq Ft Per Box',
        value: 'Sq Ft Per Box',
        class: 'with-divider',
        cellClass: 'with-divider',
      },
      { text: 'Stain Treatment', value: 'Stain Treatment', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Style Type', value: 'Style Type', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Surface Finish', value: 'Surface Finish', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Surface Texture', value: 'Surface Texture', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Texture', value: 'Texture', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Water Absorption', value: 'Water Absorption', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Wear Layer', value: 'Wear Layer', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Wet CoF', value: 'Wet CoF', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'Width', value: 'Width', class: 'with-divider', cellClass: 'with-divider' },
      { text: 'ColorURL', value: 'ColorURL', class: 'with-divider', cellClass: 'with-divider' },
    ],
    sampleDataDialog: false,
    truncColumns: true,
    truncColumnsButtonText: 'Show More',
    globalManufacturerId: null,
    pipelineDialog: false,
    manufacturerFileList: [],
    manufacturer: null,
    sourceFileName: '',
    loadingPipelineDialog: false,
    loadingSampleDataDialog: false,
  }),
  components: {
    MftMappingsDestinationToSource,
    MftMappingsSourceToDestination,
  },
  computed: {
    manufacturerName() {
      return this.$store.state.manufacturerName;
    },

    manufacturerId() {
      return this.$store.state.manufacturerId;
    },

    accessToken() {
      return this.$store.state.token;
    },
  },

  mounted() {
    this.globalManufacturerId = this.$route.params.manufacturerId;
    this.$store.commit('setManufacturerId', this.$route.params.manufacturerId);
    this.getManufacturer();
  },

  methods: {
    sourceFileSelected(item) {
      this.sourceFileName = item;
    },
    getManufacturer() {
      dal.getManufacturer(this.accessToken, this.globalManufacturerId).then((data) => {
        if (data.data && data.data.length > 0) {
          this.manufacturer = data.data[0];
          this.$store.commit('setManufacturerName', data.data[0].manufacturerName);
        }
      });
    },
    startPipelineItem() {
      this.loadingPipelineDialog = true;
      this.sourceFileName = null;
      dal
        .getManufacturersFields(this.accessToken, this.manufacturer.manufacturerName)
        .then((data) => {
          this.manufacturerFileList = data.data;
          this.pipelineDialog = true;
        })
        .catch((err) => {
          alert('Starting the pipeline failed. Please contact customer support.')
          console.log("Start Pipeline Failed Details");
          console.log(err);
        })
        .finally(() => {
          this.loadingPipelineDialog = false;
        });
    },
    startPipelineItemOk() {
      dal.startPipeline(this.accessToken, this.sourceFileName).then((data) => {
        alert('Pipeline Started');
        console.log('Pipeline Start Result');
        console.log(data);
      });
      this.pipelineDialog = false;
    },

    startPipelineItemCancel() {
      this.pipelineDialog = false;
    },
    publishItemConfirm() {
      dal.publishManufacturerMap(this.accessToken, this.manufacturerId).then((data) => {
        alert("Publish Mapping Succeeded")
        console.log("Publish console result")
        console.log(data);
      });
      this.publishDialog = false;
    },

    ToggleSampleDataColumns() {
      this.truncColumns = !this.truncColumns;
      if (this.truncColumns) {
        this.truncColumnsButtonText = 'Show All Text';
      } else {
        this.truncColumnsButtonText = 'Truncate Text';
      }
    },

    openSampleDataDialog() {
      this.loadingSampleDataDialog = true;
      dal
        .getManufacturerSampleData(this.accessToken, this.globalManufacturerId)
        .then((data) => {
          // var firstItem = data.data[0];
          // this.manufacturerSampleDataHeaders = Object.keys(firstItem).map((key) => ({
          //   text: key,
          //   value: key,
          //   class: 'with-divider',
          //   cellClass: 'with-divider',
          // }));
          this.manufacturerSampleData = data.data;
          this.sampleDataDialog = true;
        })
        .finally(() => {
          this.loadingSampleDataDialog = false;
        });
    },
    closeDialogData() {
      this.sampleDataDialog = false;
    },
  },
};
</script>

<style scoped>
.truncate {
  max-width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.no-wrap {
  white-space: nowrap;
}
.with-divider {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
::v-deep .v-dialog {
  overflow-x: hidden;
}
::v-deep .v-data-table-header {
  vertical-align: baseline;
}
</style>
