<template>
  <div>
    <v-row class="pl-3 pt-3">
      <v-col cols="12">
        <h3>Manufacturer Formats</h3>
        <ManageManufacturers />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ManageManufacturers from '../components/ManageManufacturers';

export default {
  name: 'ManageManufacturerList',
  data: () => ({}),
  components: {
    ManageManufacturers,
  },
  computed: {
    manufacturerName() {
      return this.$store.state.manufacturerName;
    },
  },

  methods: {},
};
</script>
