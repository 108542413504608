<template>
  <v-sheet :color="`grey lighten-4`">
    <v-skeleton-loader v-if="isFetching" class="mx-auto" type="table"></v-skeleton-loader>

    <v-data-table
      v-if="!isFetching && computedTableData"
      :sort-desc="[false]"
      :headers="headers"
      :items="computedTableData"
      :hide-default-footer="true"
      :items-per-page="250"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="editDialog" max-width="600px" @click:outside="closeEditDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" v-if="0"> New Item </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-overline text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.destinationFieldName"
                        label="Destination Field Name"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Mapping Type Symbol" v-model="editedItem.mappingTypeSymbol" disabled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.manufacturerFieldName"
                        label="Manufacturer Field Name"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                      <v-checkbox v-model="sourceFieldIsDelimited" label="Source Field Is Delimited"></v-checkbox>
                      <v-text-field
                        v-if="sourceFieldIsDelimited"
                        v-model="sourceFieldDelimiter"
                        label="Source Field Delimiter"
                        placeholder="Enter a single character"
                        :rules="sourceFieldDelimiterRules"
                      ></v-text-field>
                      <v-text-field
                        v-if="sourceFieldIsDelimited"
                        v-model="ordinalPosition"
                        label="Ordinal Position"
                        placeholder="Enter a positive number or leave blank"
                        type="number"
                        min="0"
                        :rules="ordinalPositionRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                      <v-select
                        v-model="selectedMappingType"
                        v-on:change="setManufacturerMappingType"
                        :items="manufacturerMappingTypes"
                        label="Manufacturer Mapping Type"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" v-if="showMappingFields" class="pt-0 pb-0">
                      <v-autocomplete
                        v-if="showMappingFields"
                        v-model="setMappingFieldTo"
                        :items="manufacturerFieldsListControl"
                        label="Source Manufacturer Field"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="showHardCodeFields" class="pt-0 pb-0">
                      <v-text-field
                        v-if="showHardCodeFields"
                        v-model="setHardCodeTo"
                        label="Hard Coded Value"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="showTransformationFields">
                      <TransformationAutocomplete
                        @onSelectTransformation="onSelectTransformation"
                        @onTransformArgumentsChange="onTransformArgumentsChange"
                        :selectedTransformArguments="setTransformArgumentsTo"
                        :selectedTransformationField="setMappingFieldTo"
                      />
                    </v-col>
                    <!-- <v-col cols="12" sm="12" md="12" v-if="showTransformationFields">
                    <v-text-field
                      v-model="computedTransformationValue"
                      label="Selected Transformation"
                      readonly
                    ></v-text-field>
                  </v-col> -->
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      left
                      v-if="editedItem && editedItem.manufacturerMappedFieldId"
                      color="blue darken-1"
                      text
                      @click="confirmUnMapDialog = true"
                    >
                      Unmap
                    </v-btn>
                  </v-col>
                  <v-col cols="6" style="display: flex; justify-content: flex-end">
                    <v-btn color="blue darken-1" text @click="closeEditDialog"> Cancel </v-btn>
                    <v-btn color="blue darken-1" text @click="saveEditDialog"> Save </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="confirmUnMapDialog" width="700">
            <v-progress-linear :active="isUnMapping" indeterminate class="mb-0"></v-progress-linear>
            <v-card>
              <v-card-title class="">Please confirm you want to unmap this mapping!</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="darken-1" text @click="confirmUnMapDialog = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text :loading="isUnMapping" @click.prevent="unMapHandler">Confirm</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.destinationFieldName`]="{ item }">
        {{ item.destinationFieldName | updateSpelling | capitalize | showTransformationFieldName }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" v-show="item.destinationFieldName.charAt(0) != '#'" @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-sheet>
</template>
<script>
import dal from '../services/dal.js';
import { MappingType } from '../constants';
import { mappingsCommonData } from '../mixins/MappingsMixin';

export default {
  name: 'MftMappingsDestinationToSource',
  mixins: [mappingsCommonData],
  components: {
    TransformationAutocomplete: () => import('./TransformationAutocomplete.vue'),
  },
  data: () => ({
    manufacturerMappingTypes: [MappingType.FIELD, MappingType.HARDCODE],
    manufacturerFieldsListControl: [],
    headers: [
      { text: 'Destination Field Name', value: 'destinationFieldName', sortable: true },
      { text: 'Manufacturer Field Name', value: 'manufacturerFieldName', sortable: true },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    defaultMappingSymbol: '<--',
  }),
  computed: {
    computedFormula() {
      if (this.setFormulaTo) {
        return this.sourceFieldIsDelimited
          ? `[${this.setFormulaTo}, ${this.sourceFieldDelimiter}, ${this.ordinalPosition || 0}]`
          : null;
      }
      return '';
    },
  },
  mounted() {
    this.initData(this.manufacturerId);
  },
  methods: {
    onTransformArgumentsChange(val) {
      this.setTransformArgumentsTo = val;
    },
    initData(manufacturerId) {
      // for dropdown
      dal.getManufacturesMappings(this.accessToken, manufacturerId).then((data) => {
        this.globalManufacturerId = manufacturerId;
        if (data && data.data && data.data.length) {
          this.manufacturerMappings = data.data.sort(this.sortFn);
        }
      });

      this.isFetching = true;
      // for table
      dal
        .getManufacturersFieldsByManufacturerId(this.accessToken, manufacturerId)
        .then((data) => {
          this.manufacturerFieldsListControl = data.data.map((item) => item.manufacturerFieldName);
        })
        .finally(() => {
          this.isFetching = false;
        });
    },

    editItem(item) {
      this.setTransformArgumentsTo = item.transformArguments || null;
      this.setFormulaTo = item.mappingTypeFormula || null;
      this.setHardCodeTo = item.hardCodeValue || null;
      this.setMappingFieldTo = item.manufacturerFieldName || null;

      if (this.setFormulaTo) {
        this.populateSourceDelimitedFields();
      }
      if (item.destinationFieldName && item.destinationFieldName.charAt(0) == '#') {
        this.selectedMappingType = MappingType.TRANSFORM;
        this.showMappingFields = false;
        this.showHardCodeFields = false;
      } else if (this.setHardCodeTo) {
        this.selectedMappingType = MappingType.HARDCODE;
        this.showMappingFields = false;
        this.showHardCodeFields = true;
      } else {
        this.selectedMappingType = MappingType.FIELD;
        this.showMappingFields = true;
        this.showHardCodeFields = false;
        this.showTransformationFields = false;
      }

      this.editedIndex = item.manufacturerMappedFieldId;
      this.editedItem = {
        ...item,
        mappingTypeSymbol: this.defaultMappingSymbol,
        sourceFieldDelimiter: this.sourceFieldDelimiter,
      };
      this.editDialog = true;
    },

    saveEditDialog() {
      if (this.editedIndex > -1) {
        if (this.selectedMappingType === MappingType.FIELD) {
          const reqBody = {
            manufacturerMappedFieldId: this.editedItem.manufacturerMappedFieldId,
            manufacturerId: this.manufacturerId,
            formula: this.formulaForNonTransformTypeMapping,
            hardCodeValue: null,
            transformArguments: null,
            manufacturerFieldName: this.setMappingFieldTo,
            destinationFieldName: this.editedItem.destinationFieldName,
          };
          this.updateMapping(reqBody)
            .then(() => {
              this.closeEditDialog();
              this.initData(this.manufacturerId);
            })
            .finally(() => {
              if (this.editDialog) {
                this.closeEditDialog();
              }
            });
        }
        if (this.selectedMappingType === MappingType.HARDCODE) {
          const reqBody = {
            manufacturerMappedFieldId: this.editedItem.manufacturerMappedFieldId,
            manufacturerId: this.manufacturerId,
            formula: null,
            hardCodeValue: this.setHardCodeTo,
            transformArguments: null,
            manufacturerFieldName: this.setHardCodeTo,
            destinationFieldName: this.editedItem.destinationFieldName,
          };
          this.updateMapping(reqBody)
            .then(() => {
              this.closeEditDialog();
              this.initData(this.manufacturerId);
            })
            .finally(() => {
              if (this.editDialog) {
                this.closeEditDialog();
              }
            });
        }
        if (this.selectedMappingType === MappingType.TRANSFORM) {
          const reqBody = {
            manufacturerMappedFieldId: this.editedItem.manufacturerMappedFieldId,
            manufacturerId: this.manufacturerId,
            formula: this.computedFormula,
            hardCodeValue: null,
            transformArguments: this.setTransformArgumentsTo,
            manufacturerFieldName: this.sourceField,
            destinationFieldName: this.setMappingFieldTo,
          };
          this.updateMapping(reqBody)
            .then(() => {
              this.closeEditDialog();
              this.initData(this.manufacturerId);
            })
            .finally(() => {
              if (this.editDialog) {
                this.closeEditDialog();
              }
            });
        }
      }
    },
    sortFn(a, b) {
      const nameA = a.destinationFieldName.toUpperCase(); // ignore upper and lowercase
      const nameB = b.destinationFieldName.toUpperCase(); // ignore upper and lowercase
      //sort the # fields to the bottom

      if (nameA.charAt(0) === '#' && nameB.charAt(0) !== '#') {
        return 1;
      }
      if (nameA.charAt(0) !== '#' && nameB.charAt(0) === '#') {
        return -1;
      }

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
};
</script>
