<template>
  <div v-if="!$auth.loading">
    <LoginPage />
  </div>
</template>

<script>
import LoginPage from '../components/LoginPage';

export default {
  name: 'Login',
  components: {
    LoginPage,
  },
  mounted() {},
};
</script>
