<template>
  <div>
    <AddNew />
  </div>
</template>

<script>
import HomePage from '../components/HomePage';
import AddNew from '../components/AddNew';
export default {
  name: 'AddManufacturer',
  components: { HomePage, AddNew },
};
</script>
