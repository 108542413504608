import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import BlobServiceClient from '@azure/storage-blob';
import JsonCSV from 'vue-json-csv'
// Import the Auth0 configuration
import { domain, clientId, audience } from '../auth_config.json';

// Import the plugin here
import { Auth0Plugin } from './auth';
import truncate from './shared/truncatefilter';

Vue.component('downloadCsv', JsonCSV)
Vue.config.productionTip = false;
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});
new Vue({
  router,
  store,
  vuetify,
  BlobServiceClient,
  render: function (h) {
    return h(App);
  },
}).$mount('#app');
Vue.filter('truncate', truncate);
