<template>
  <div>
    <v-data-table
      v-if="manufacturers"
      :headers="headers"
      :items="manufacturers"
      :search="search"
      :footer-props="pagination"
      sort-by="manufacturerName"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-row style="width: 100%">
                <v-col class="pl-0" cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search Manufacturer "
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col style="display: flex; justify-content: flex-end" cols="12" sm="6" md="6" right>
                  <v-btn style="margin-left: 10px" color="primary" dark class="mb-2" :to="{ name: 'AddManufacturer' }">
                    Create Manufacturer Format
                  </v-btn>
                  <!-- <v-btn color="primary" dark class="ml-2 mb-2" v-bind="attrs" v-on="on">
                    Map New Format
                  </v-btn> -->
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.manufacturerName"
                        label="Manufacturer Format Name"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="editedItem.manufacturerId" label="System Id" disabled></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-if="manufacturerFileList"
                        v-on:change="sourceFileSelected"
                        :items="manufacturerFileList"
                        label="Select Source File"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn
                        v-if="sourceFileName && !callInProcess"
                        color="primary"
                        dark
                        class="mb-2"
                        v-on:click="addMappedFields"
                      >
                        Add Source Fields
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn v-if="!callInProcess" color="primary" dark class="mb-2" v-on:click="autoMapFields">
                        Auto-map fields
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-card v-if="callInProcess">
                        Processing...
                        <br />
                        <br />
                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        <br />
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="resultDialog" width="500">
            <v-card>
              <v-card-title class="text-h5">Completed</v-card-title>
              <v-card-text>{{ resultMessage }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="resultDialog = false">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.manufacturerName`]="{ item }">
        <router-link
          :to="{
            name: 'ManageManufacturerMappings',
            params: { manufacturerId: item.manufacturerId },
          }"
        >
          {{ item.manufacturerName }}
        </router-link>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)"> mdi-trash-can </v-icon>
      </template>      
      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
        No data found
      </template>
    </v-data-table>
  </div>
</template>
<script>
import dal from '../services/dal.js';
export default {
  name: 'ManageManufacturers',
  data: () => ({
    search: '',
    dialog: false,
    dialogDelete: false,
    resultDialog: false,
    callInProcess: false,
    publishDialog: false,
    sourceFileName: '',
    manufacturers: [],
    headers: [
      {
        text: 'Manufacturer Format Name',
        align: 'start',
        sortable: true,
        value: 'manufacturerName',
      },
      { text: 'Actions', value: 'actions', sortable: false },
    ],

    editedIndex: -1,
    resultMessage: 'none',
    editedItem: {
      manufacturerName: '',
      manufacturerId: 0,
      updateDate: null,
      createDate: null,
      isActive: 1,
    },
    manufacturerFileList: [],
    defaultItem: {
      manufacturerName: '',
      manufacturerId: 0,
      updateDate: null,
      createDate: null,
      isActive: 1,
    },
    pagination: {
      'items-per-page-options': [10, 20, 50, -1],
    },
  }),

  mounted() {
    dal.getManufacturers(this.accessToken).then((data) => {
      if (data && data.data && data.data.result) {
        this.manufacturers = data.data.result;
        this.manufacturers.map((item, index) => {
          item.selectedItem = false;
          this.$set(this.manufacturers, index, item);
        });
      }
    });
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },

    accessToken() {
      return this.$store.state.token;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    addMappedFields() {
      this.callInProcess = true;
      dal
        .addManufacturerFields(
          this.accessToken,
          this.editedItem.manufacturerId,
          this.editedItem.manufacturerName,
          this.sourceFileName
        )
        .then((data) => {
          this.callInProcess = false;
          this.resultMessage = data;
          this.resultDialog = true;
        });
    },

    autoMapFields() {
      this.callInProcess = true;
      dal.autoMapFields(this.accessToken, this.editedItem.manufacturerId).then((data) => {
        this.callInProcess = false;
        this.resultMessage = data;
        this.resultDialog = true;
        this.emitSearchEvent(this.editedItem.manufacturerId);
      });
    },

    emitSearchEvent(manufacturerId) {
      this.$emit('selectedMft', manufacturerId);
    },

    sourceFileSelected(item) {
      this.sourceFileName = item;
    },

    syncNow() {
      //this.isSyncing=true
      dal.getManufacturers(this.accessToken).then((data) => {
        // this.isSyncing=false
        this.manufacturers = data.data.result;
        this.manufacturers.map((item, index) => {
          item.selectedItem = false;
          this.$set(this.manufacturers, index, item);
        });
      });
    },
    initialize() {
      // this.manufacturers =[
      //   {
      //      manufacturerName: '',
      //      manufacturerId: 0,
      //      updateDate: null,
      //      createDate: null,
      //      isActive: 1,
      //   }
      // ]
      //this.syncNow()
    },
    handleClick(row) {
      // this.selectedItem = row;
      // this.$store.commit("setManufacturerId", row.manufacturerId);
      // this.$store.commit("setManufacturerName", row.manufacturerName);
      // this.$router.push(`/manage-manufacturer/mappings/${row.manufacturerId}`);
      // this.emitSearchEvent(row.manufacturerId);
    },
    editItem(item) {
      this.editedIndex = this.manufacturers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      dal.getManufacturersFields(this.accessToken, this.editedItem.manufacturerName).then((data) => {
        this.manufacturerFileList = data.data;
      });
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.manufacturers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      dal.deleteManufacturers(this.accessToken, this.editedItem.manufacturerId).then((data) => {
        alert('Manufacturer Deleted');
      });
      this.manufacturers.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    publishItem(item) {
      this.editedIndex = this.manufacturers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.publishDialog = true;
    },

    publishItemConfirm(item) {
      dal.publishManufacturerMap(this.accessToken, this.editedItem.manufacturerId).then((data) => {
        console.log("Publish results")
        console.log(data);
        alert('Mappings have been published');
      });
      this.publishDialog = false;
      this.editedIndex = -1;
    },

    close() {
      this.dialog = false;
      this.sourceFileName = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.newFormTitle = 'Create Manufacturer Format';
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.manufacturers[this.editedIndex], this.editedItem);
      } else {
        this.manufacturers.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
