<template>
  <div>
    <v-row class="pl-3 pt-3">
      <v-col cols="12">
        <h3>Intake Process Job Detail</h3>
      </v-col>
    </v-row>
    <v-row class="pl-3 pt-3">
      <v-col cols="12">
        <strong> Manufacturer Format Name:</strong> {{ intakeProcessingDetail.manufacturerName }}
      </v-col>
      <v-col cols="12"><strong> Process Identifier:</strong> {{ intakeProcessingDetail.processIdentifier }} </v-col>
      <v-col cols="12"><strong> File Name:</strong> {{ intakeProcessingDetail.fileName }} </v-col>
      <v-col cols="12"><strong> Status:</strong> {{ intakeProcessingDetail.status }} </v-col>
      <v-col cols="12"><strong> Start Date:</strong> {{ intakeProcessingDetail.startDate }} </v-col>
      <v-col cols="12"><strong> End Date:</strong> {{ intakeProcessingDetail.endDate }} </v-col>
      <v-col cols="12">
        <strong> Records Received In File</strong>: {{ intakeProcessingDetail.recordsReceivedInFile }}
      </v-col>
      <v-col cols="12"><strong> Products Updated:</strong> {{ intakeProcessingDetail.productsUpdated }} </v-col>
      <v-col cols="12"><strong> Products Added:</strong> {{ intakeProcessingDetail.productsAdded }} </v-col>
    </v-row>
  </div>
</template>

<script>
import dal from '../services/dal';

export default {
  name: 'IntakeProgressDetail',
  data: () => ({
    intakeId: 0,
    intakeProcessingDetail: [],
  }),
  components: {},
  computed: {
    accessToken() {
      return this.$store.state.token;
    },
  },

  mounted() {
    this.intakeId = this.$route.params.intakeId;

    this.getIntakeProgressDetail();
  },

  methods: {
    getIntakeProgressDetail() {
      dal.getIntakeProgressDetail(this.accessToken, this.intakeId).then((data) => {
        this.intakeProcessingDetail = data.data[0];
      });
    },
  },
};
</script>
