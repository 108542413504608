<template>
  <div>
    <v-row class="pl-3 pt-3">
      <v-col cols="12">
        <h3>
          {{ queueName ? queueName[0].toUpperCase() + queueName.slice(1) : queueName }} Dead Letter Messages &nbsp;({{
            sourceFileName
          }})
        </h3>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="deadLetterMessages"
      :options.sync="options"
      :server-items-length="totalRecords"
      :loading="loading"
      loading-text="Analyzing Dead Letter Messages, this may take a while"
      :footer-props="{
        'items-per-page-options': [10, 25, 50],
      }"
      class="elevation-1"
    >
      <template v-slot:[`item.message`]="{ item }">
        {{ item.message | truncate(70) }}
        <v-icon title="copy message" small color="blue" class="ml-1" @click="copyToClipBoard(item.message)">
          mdi-content-copy
        </v-icon>
      </template>
      <template v-slot:[`item.payload`]="{ item }">
        {{ item.payload | truncate(70) }}
        <v-icon title="copy payload" small color="blue" class="ml-1" @click="copyToClipBoard(item.payload)">
          mdi-content-copy
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dal from '../services/dal';

export default {
  name: 'DeadLetterMessages',
  data: () => ({
    page: 0,
    visitedPage: 0,
    numberOfPages: 10,
    totalRecords: 0,
    loading: true,
    options: {},
    headers: [
      { text: 'Product Id', value: 'productId' },
      { text: 'Message', value: 'message' },
      { text: 'Payload', value: 'payload' },
    ],
    queueName: '',
    sourceFileName: '',
    fromSequenceNumber: 0,
    visitedPageFromSequenceNumber: 0,
    queueCount: 0,
    deadLetterMessages: [],
  }),
  components: {},
  computed: {
    accessToken() {
      return this.$store.state.token;
    },
  },

  mounted() {
    this.queueName = this.$route.params.queueName;
    this.sourceFileName = this.$route.params.sourceFileName;
    this.visitedPageFromSequenceNumber = this.fromSequenceNumber = this.$route.params.fromSequenceNumber;
    this.totalRecords = this.queueCount = this.$route.params.queueCount;
  },

  watch: {
    options: {
      handler() {
        this.getDeadLetterMessages();
      },
    },
    deep: true,
  },

  methods: {
    copyToClipBoard(textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    },

    getDeadLetterMessages() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      if ((page == undefined) | (itemsPerPage == undefined)) return;

      if (page == 1)
        this.visitedPageFromSequenceNumber = this.fromSequenceNumber = this.$route.params.fromSequenceNumber;

      const loadedrecords = (page - 1) * itemsPerPage;
      const remainingQueueCount = this.queueCount - loadedrecords;

      if (page < this.visitedPage) this.fromSequenceNumber = this.visitedPageFromSequenceNumber;

      dal
        .getDeadLetterMessages(
          this.accessToken,
          this.queueName,
          this.sourceFileName,
          itemsPerPage,
          this.fromSequenceNumber,
          remainingQueueCount
        )
        .then((response) => {
          this.loading = false;
          this.deadLetterMessages = response.data.Data;

          if (loadedrecords + itemsPerPage < this.queueCount)
            this.visitedPageFromSequenceNumber = this.fromSequenceNumber;

          this.fromSequenceNumber = response.data.LastSequenceNumber;
          this.visitedPage = page;
        });
    },
  },
};
</script>
