import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    manufacturerId: null,
    manufacturerName: null,
    isAuthenticated: null,
    token: null,
    userPermissions: null,
  },
  mutations: {
    setManufacturerId(state, manufacturerId) {
      state.manufacturerId = manufacturerId;
    },

    setManufacturerName(state, manufacturerName) {
      state.manufacturerName = manufacturerName;
    },

    setToken(state, token) {
      state.token = token;
    },

    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },

    setUserPermissions(state, userPermissions) {
      state.userPermissions = userPermissions;
    },
  },
  actions: {},
  modules: {},
});
