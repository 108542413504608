import axios from 'axios';
import config from '../config.json';

export default {
  baseUrl: config.API_URL,
  sbURL: config.CREATE_MESSAGE_URL,
  // kickOffSync() {
  //   return axios({
  //     method: 'get',
  //     url: `https://datapipelinesmartsheet.azurewebsites.net/api/singlecasesync`,
  //     headers: {},
  //   })
  //     .then((res) => res)
  //     .catch((err) => console.log(err));
  // },

  addManufacturer(token, manufactureName) {
    const data = JSON.stringify({
      ManufacturerName: manufactureName,
    });
    const url = `${this.baseUrl}/Manufacturer`;
    return axios({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  },

  startPipeline(token, ManufacturerFileName) {
    const data = JSON.stringify({
      fileName: ManufacturerFileName,
    });
    const url = `${this.baseUrl}/StartPipeline`;
    return axios({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  },

  addManufacturerFields(token, manufacturerId, manufactureName, ManufacturerFileName) {
    const data = JSON.stringify({
      ManufacturerId: manufacturerId,
      ManufacturerName: manufactureName,
      ManufacturerFileName: ManufacturerFileName,
    });
    const url = `${this.baseUrl}/AddManufactureFields`;
    return axios({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  },

  publishManufacturerMap(token, manufacturerId) {
    const url = `${this.baseUrl}/PublishManufactureMap/${manufacturerId}`;
    return axios({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  },

  updateMappingField(token, manufacturerId, manufactureFieldName, DestinationFieldName) {
    const data = JSON.stringify({
      ManufacturerId: manufacturerId,
      ManufacturerFieldName: manufactureFieldName,
      DestinationFieldName: DestinationFieldName,
    });
    const url = `${this.baseUrl}/MappingField/Map`;
    return axios({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  },

  updateMappingFormula(token, manufacturerId, Formula, DestinationFieldName) {
    const data = JSON.stringify({
      ManufacturerId: manufacturerId,
      Formula: Formula,
      DestinationFieldName: DestinationFieldName,
    });
    const url = `${this.baseUrl}/MappingField/Formula`;
    return axios({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  },

  updateMappingTransformation(token, manufacturerId, transformation, DestinationFieldDescription) {
    const data = JSON.stringify({
      ManufacturerId: manufacturerId,
      Transform: transformation,
      DestinationFieldDescription: DestinationFieldDescription,
    });
    const url = `${this.baseUrl}/MappingField/Transform`;
    return axios({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  },

  updateMappingHardCode(token, manufacturerId, HardCode, DestinationFieldName) {
    const data = JSON.stringify({
      ManufacturerId: manufacturerId,
      HardCode: HardCode,
      DestinationFieldName: DestinationFieldName,
    });
    const url = `${this.baseUrl}/MappingField/HardCode`;
    return axios({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  },

  autoMapFields(token, manufacturerId) {
    const url = `${this.baseUrl}/AutoMapFields/${manufacturerId}`;
    return axios({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  },

  getManufacturesMappings(token, manufacturerId) {
    const url = `${this.baseUrl}/Manufacturer/${manufacturerId}/vwMappedField`;
    return axios({
      method: 'get',
      url: url,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getManufacturerSourceToDestinationMappings(token, manufacturerId) {
    const url = `${this.baseUrl}/Manufacturer/${manufacturerId}/vwSourceToDestinationMappedField`;
    return axios({
      method: 'get',
      url: url,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getManufacturer(token, manufacturerId) {
    const url = `${this.baseUrl}/Manufacturer/${manufacturerId}/Manufacturer`;
    return axios({
      method: 'get',
      url: url,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getManufacturerDestinationFields(token) {
    const url = `${this.baseUrl}/GetAllItems/DestinationField`;
    return axios({
      method: 'get',
      url: url,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getManufacturerSampleData(token, manufacturerId) {
    const url = `${this.baseUrl}/GetManufacturerSourceData/${manufacturerId}`;
    return axios({
      method: 'get',
      url: url,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getManufacturersFields(token, manufacturerName) {
    const url = `${this.baseUrl}/GetSourceFileNames/${manufacturerName}`;
    return axios({
      method: 'get',
      url: url,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getManufacturersFieldsByManufacturerId(token, manufacturerId) {
    const url = `${this.baseUrl}/Manufacturer/${manufacturerId}/ManufacturerField`;
    return axios({
      method: 'get',
      url: url,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getManufacturerNamesFromFiles(token) {
    //const url =
    return axios({
      method: 'get',
      url: `${this.baseUrl}/GetManufacturerNameFromFiles`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  deleteManufacturers(token, manufacturerId) {
    return axios({
      method: 'delete',
      url: `${this.baseUrl}/DeleteManufacturer/${manufacturerId}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getManufacturers(token) {
    return axios({
      method: 'get',
      url: `${this.baseUrl}/Manufacturer`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  syncNow() {
    setTimeout(() => {
      var res = {
        data: {
          Message: 'test',
        },
      };
      return res;
    }, 2000);
  },

  getIntakeProgressData(token, filters = {}) {
    const url = `${this.baseUrl}/IntakeProgress`;
    return axios({
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: filters,
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getIntakeProgressDetail(token, intakeId = null) {
    const url = `${this.baseUrl}/IntakeProgress?intakeId=${intakeId}`;
    return axios({
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  uploadFile(token, formData) {
    const url = `${this.baseUrl}/UploadFile`;
    return axios({
      method: 'post',
      url: url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },
  unMap(token, manufacturerId, manufacturerMappedFieldId) {
    const url = `${this.baseUrl}/UnMapField/${manufacturerMappedFieldId}`;
    return axios({
      method: 'post',
      url: url,
      data: { ManufacturerId: manufacturerId },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  updateMapping(token, reqBody) {
    const url = `${this.baseUrl}/MappingField/UpdateMapping`;
    return axios({
      method: 'post',
      url: url,
      data: reqBody,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getDeadLetterSummary(token) {
    const url = `${this.baseUrl}/DeadLetterSummary`;
    return axios({
      method: 'get',
      url: url,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getDeadLetterRollup(token, queueName) {
    const url = `${this.baseUrl}/DeadLetterRollup/${queueName}`;
    return axios({
      method: 'get',
      url: url,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getDeadLetterMessages(token, queueName, sourceFileName, itemsPerPage, fromSequenceNumber, remainingQueueCount) {
    const url = `${this.baseUrl}/messageDetail`;
    return axios({
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        queueName: queueName,
        sourceFileName: sourceFileName,
        fromSequenceNumber: fromSequenceNumber.toString(),
        itemsPerPage: itemsPerPage.toString(),
        remainingQueueCount: remainingQueueCount.toString(),
      },
    })
      .then((res) => res)
      .catch((err) => console.log(err));
  },
};
