<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h3 class="display-1 font-weight-bold mb-3">PDI Manager</h3>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <div v-if="!$auth.loading">
          <!-- show login when not authenticated -->
          <v-btn v-if="!$auth.isAuthenticated" @click="login">Log in</v-btn>
          <!-- show logout when authenticated -->
          <v-btn v-else @click="logout">Log out</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'LoginPage',
  created() {},
  methods: {
    // Log the user in
    async login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },

    async testLogin() {
      const token = await this.$auth.getTokenSilently();
      this.$store.commit('setToken', token);
      //
    },
  },
};
</script>
