<template>
  <div>
    <v-row class="pl-3 pt-3">
      <v-col cols="12">
        <h3>Dead Letter Summary</h3>
      </v-col>
    </v-row>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(item, i) in deadLetterSummary"
        :key="i"
        @click="loadDeadLetterRollup($event, item.queueName)"
      >
        <v-expansion-panel-header>
          {{ item.queueName[0].toUpperCase() + item.queueName.slice(1) }}&nbsp;&nbsp;({{ item.count }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="deadLetterRollup"
            :loading="loading"
            loading-text="Analyzing Dead Letter Messages, this may take a while"
            class="elevation-1"
          >
            <template v-slot:[`item.sourceFileName`]="{ item }">
              <router-link
                :to="{
                  name: 'DeadLetterMessages',
                  params: {
                    queueName: item.queueName,
                    sourceFileName: item.sourceFileName,
                    fromSequenceNumber: item.fromSequenceNumber,
                    queueCount: item.count,
                  },
                }"
              >
                {{ item.sourceFileName }}
              </router-link>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import dal from '../services/dal';

export default {
  name: 'DeadLetterSummary',
  data: () => ({
    loading: true,
    options: {},
    headers: [
      { text: 'Source File', value: 'sourceFileName' },
      { text: 'Count', value: 'count' },
    ],
    deadLetterRollup: [],
    deadLetterSummary: [],
    queueName: '',
    queueCount: 0,
  }),
  components: {},
  computed: {
    accessToken() {
      return this.$store.state.token;
    },
  },

  mounted() {
    this.getDeadLetterSummary();
  },

  methods: {
    getDeadLetterSummary() {
      dal.getDeadLetterSummary(this.accessToken).then((data) => {
        this.deadLetterSummary = data.data;
      });
    },

    getDeadLetterRollup(queueName) {
      dal.getDeadLetterRollup(this.accessToken, queueName).then((data) => {
        this.deadLetterRollup = data.data;
      });
    },

    loadDeadLetterRollup(event, queueName) {
      if (event.target.classList.contains('v-expansion-panel-header--active')) return;
      this.loading = true;
      this.deadLetterRollup = [];
      this.queueName = queueName;
      dal.getDeadLetterRollup(this.accessToken, this.queueName).then((response) => {
        this.loading = false;
        this.deadLetterRollup = response.data;
      });
    },
  },
};
</script>
